@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color:  rgb(17, 30, 49);
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}
.pagination > .active > a {
  background-color: #238FBE;
  border-color: #238FBE;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #238FBE;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #238FBE;
  border-color: #238FBE;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #0369a1;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
